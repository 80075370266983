import axios from "../../../lib/axios";

export async function getAll() {
    const response = await axios.get('/agreements');
    return response;
}

export async function get(id) {
    const response = await axios.get('/agreements/'+id);
    return response.data;
}

export async function update(id, data) {
    data._method = 'PUT';
    const response = await axios.post('/agreements/'+id, data);
    return response.data;
}