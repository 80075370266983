import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FrontLayout from "../../../../components/FrontLayout/Layout";
import Loading from "../../../../components/Loading";
import { ReactComponent as UserIcon } from '../../../../assets/images/icon-user.svg';
import {get as getAgreement } from "../../../../services/Apis/Front/Agreements.service";
import IAgreement from "../../../../interfaces/IAgreement";
import {useParams} from "react-router-dom";
import IAgreementContact from "../../../../interfaces/IAgreementContact";
import AccountAgreementContact from "./components/AgreementContact";

function AccountAgreementContacts() {

    const [isLoading, setIsLoading] = useState(true);
    const [agreement, setAgreement] = useState<IAgreement>();
    let { agreementId } = useParams();

    const handleReload = async () => {
        setIsLoading(true);
        const response = await getAgreement(agreementId);
        setAgreement(response);
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            setIsLoading(false);
            const response = await getAgreement(agreementId);
            setAgreement(response);
        })();
    }, []);

  return (
      <FrontLayout>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <Row className={`row`}>
                          {agreement?.contactPersons?.map((contactPerson: IAgreementContact, index: number) => (
                              <Col xl={6}>
                                  <AccountAgreementContact handleReload={handleReload} agreement={agreement} values={contactPerson} title={`Arving ` + (index + 1)} />
                              </Col>
                          ))}
                      </Row>
                  </>
              }
          </>
      </FrontLayout>
  );
}

export default AccountAgreementContacts;
