import axios from "../../../lib/axios";

export async function create(agreementId, data) {
    const response = await axios.post('/admin/agreements/' + agreementId + '/contacts', data);
    return response.data;
}

export async function update(agreementId, contactId, data) {
    data._method = 'PUT';
    const response = await axios.post('/admin/agreements/' + agreementId + '/contacts/' + contactId, data);
    return response.data;
}

export async function sendForSignature(agreementId, contactId) {
    const response = await axios.post('/admin/agreements/' + agreementId + '/contacts/' + contactId + '/signature');
    return response.data;
}
